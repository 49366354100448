<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  color: {
    type: String as PropType<'blue' | 'white' | 'black' | 'red'>,
    default: 'blue',
  },
  isRectangleBadge: {
    type: Boolean,
    default: false
  }
})

const currentBackgroundColor = ref('var(--color-navy-450)')
const currentColor = ref('var(--color-primary-white)')

if (props.color === 'white') {
  currentBackgroundColor.value = 'var(--color-white)'
  currentColor.value = 'var(--color-navy-450)'
} else if (props.color === 'black') {
  currentBackgroundColor.value = 'var(--color-primary-black)'
  currentColor.value = 'var(--color-primary-white)'
} else if (props.color === 'red') {
  currentBackgroundColor.value = 'var(--color-red)'
  currentColor.value = 'var(--color-primary-white)'
}
</script>

<template>
  <span
    class="container-badge"
    :class="{
      'badge-circle': !isRectangleBadge,
      'badge-rectangle': isRectangleBadge
    }"
  >
    <slot />
  </span>
</template>

<style scoped lang="scss">
.container-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  background-color: v-bind(currentBackgroundColor);
  color: v-bind(currentColor);
  text-transform: uppercase;

  &.badge-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &.badge-rectangle {
    padding: 0.5em 0.7em;
  }
}
</style>
